<template>
  <div class="modal-card" style="width: 400px; position: relative">
      <header class="modal-card-head">
          <p class="modal-card-title">Firma de validación</p>
        <div style="text-align: center">
          <button
              type="button"
              class="delete"
              @click="$parent.close()"/>
        </div>
      </header>
    <section class="modal-card-body">
        <div class="column is-12">
            <b-field label="Clave">
              <b-input
                  placeholder="Clave"
                  required>
              </b-input>
            </b-field>
        </div>
      <div class="column is-12">
        <b-field label="Password">
          <b-input
              type="password"
              password-reveal
              placeholder="Password"
              required>
          </b-input>
        </b-field>
      </div>

    </section>
    <footer class="modal-card-foot">
      <b-button native-type="button" type="is-primary" @click="showEquipmentCommunication=true">Aceptar</b-button>
      <b-button native-type="button" type="is-primary"  @click="$parent.close()">Cancelar</b-button>
    </footer>
    <b-modal v-model="showEquipmentCommunication"
             width="80vw">
      <equipment-communication/>
    </b-modal>
  </div>
  </template>

<script>
import EquipmentCommunication from "@/components/tools/EquipmentCommunication";
export default {
  name: "ValidationSign",
  components: {
    EquipmentCommunication,
  },
  data() {
    return{
      showEquipmentCommunication: false,
    }
  }
}
</script>

<style scoped>

</style>