<template>
    <div>
        <div class="section-title section-title_app">
            <h3>Comunicación con: {{ selectedItem.name || 'nombre de equipo' }}</h3>
        </div>
        <div class="columns is-multiline">
            <div class="column is-12 has-background-white p-5">
                <b-menu>
                    <b-menu-list>
                        <b-menu-item :expanded.sync="isOpenMenu" @click="handleCloseMenu" icon="settings">
                            <template #label="props">
                                Equipos
                                <b-icon class="is-pulled-right"
                                        :icon="props.expanded ? 'menu-up' : 'menu-down'"></b-icon>
                            </template>
                            <b-menu-item
                                    v-if="hardwareList.length"
                                    :label="item.name" v-for="(item, index) in hardwareList"
                                    @click="handleMenuClick(item)"
                                    :key="`hw_${(item.name ? (item.name + '_' + index): '')}`">
                                <b-menu-item
                                        v-if="item.children && item.children.length"
                                        v-for="(item2, index2) in item.children"
                                        :label="item2.name"
                                        @click="handleMenuClick(item2)"
                                        :key="`hw_${(item2.name ? (item2.name + '_' + index2): '')}`"
                                >
                                    <b-menu-item
                                            v-if="item2.children && item2.children.length"
                                            v-for="(item3, index3) in item2.children"
                                            :label="item3.name"
                                            @click="handleMenuClick(item3)"
                                            :key="`hw_${(item3.name ? (item3.name + '_' + index3): '')}`"
                                    ></b-menu-item>
                                </b-menu-item>
                            </b-menu-item>
                        </b-menu-item>
                    </b-menu-list>
                </b-menu>
            </div>
            <div class="column is-12 has-background-white p-5" v-if="selectedItem.code">
                <section>
                    <h3 class="subtitle">{{selectedItem.name}}</h3>
                    <b-tabs type="is-toggle" expanded>
                        <b-tab-item label="Resultados">
                            <div>
                                <div style="display: flex; flex-wrap: wrap; gap: 16px;">
                                    <b-button type="is-ghost">Presione F2 para comunicarse con
                                        {{ '' || 'NOMBRE DE EQUIPO' }}
                                    </b-button>

                                    <b-field style="padding: 8px">
                                        <b-checkbox>Crear tramas calidad</b-checkbox>
                                    </b-field>
                                </div>


                                <div>
                                    <b-table
                                            striped
                                            bordered
                                            narrowed
                                            sticky-header
                                            height="100%">
                                        <b-table-column label="CassPoss"></b-table-column>
                                        <b-table-column label="Orden"></b-table-column>
                                        <b-table-column label="Procedencia"></b-table-column>
                                        <b-table-column label="Paciente"></b-table-column>
                                        <b-table-column label="Registro"></b-table-column>
                                        <b-table-column label="Anormal"></b-table-column>
                                        <b-table-column label="Validado"></b-table-column>
                                        <b-table-column label="Usuario"></b-table-column>
                                        <b-table-column label="Fecha"></b-table-column>
                                        <b-table-column label="Hora"></b-table-column>
                                        <template slot="empty">
                                            <section class="section">
                                                <div class="content has-text-grey has-text-centered">
                                                    <p>
                                                        <b-icon
                                                                icon="box-open"
                                                                size="is-large">
                                                        </b-icon>
                                                    </p>
                                                    <p>Sin información para mostrar.</p>
                                                </div>
                                            </section>
                                        </template>
                                    </b-table>
                                </div>

                                <div style="display: flex; flex-wrap: wrap; gap: 16px; padding: 16px;">
                                    <b-tooltip label="Cancelar">
                                        <b-button
                                                label="Esc-Cancelar"
                                                type="is-ghost"/>
                                    </b-tooltip>
                                    <b-tooltip label="Editar">
                                        <b-button
                                                label="F3-Editar"
                                                type="is-ghost"
                                                @click="showValidationSign=true"/>
                                    </b-tooltip>
                                    <b-tooltip label="Eliminar">
                                        <b-button
                                                label="F4-Eliminar"
                                                type="is-ghost"/>
                                    </b-tooltip>
                                    <b-tooltip label="Buscar">
                                        <b-button
                                                label="F8-Buscar"
                                                type="is-ghost"/>
                                    </b-tooltip>
                                    <b-tooltip label="Estudios">
                                        <b-button
                                                label="F9-Estudios"
                                                type="is-ghost"/>
                                    </b-tooltip>
                                    <b-tooltip label="Interprete">
                                        <b-button
                                                label="F10-Interprete"
                                                type="is-ghost"/>
                                    </b-tooltip>
                                    <b-tooltip label="Refresh">
                                        <b-button
                                                label="Refresh"
                                                type="is-ghost"/>
                                    </b-tooltip>
                                    <p>Ultima Sesión: Cantidad</p>
                                    <p>Total Leídos: Total</p>

                                </div>

                            </div>
                        </b-tab-item>
                        <b-tab-item label="Configuración">
                            <div class="column is-12 columns is-multiline">
                                <div class="column is-12 is-7-desktop is-8-widescreen has-background-white is-multiline">
                                    <div class="is-divider" data-content="PARÁMETROS"></div>
                                    <div class="columns is-multiline">
                                        <div class="column is-4">
                                            <b-field label="Puerto"
                                                     label-position="on-border">
                                                <b-select expanded v-model="hardware.port">
                                                    <option>Seleccionar</option>
                                                    <option value="COM1">COM1</option>
                                                    <option value="COM2">COM2</option>
                                                    <option value="COM3">COM3</option>
                                                    <option value="COM4">COM4</option>
                                                    <option value="COM5">COM5</option>
                                                </b-select>
                                            </b-field>
                                        </div>
                                        <div class="column is-8">
                                            <p>Puerto de comunicaciones para Envío/Recepción</p>
                                        </div>

                                        <div class="column is-4">
                                            <b-field label="Baudios"
                                                     label-position="on-border">
                                                <b-select expanded v-model="hardware.baud_rate">
                                                    <option>Seleccionar</option>
                                                    <option value="1200">1200</option>
                                                    <option value="2400">2400</option>
                                                    <option value="9600">9600</option>
                                                    <option value="14000">14000</option>
                                                    <option value="19200">19200</option>
                                                    <option value="28800">28800</option>
                                                    <option value="33600">33600</option>
                                                    <option value="57600">57600</option>
                                                </b-select>
                                            </b-field>
                                        </div>
                                        <div class="column is-8">
                                            <p>Velocidad de Transmisión de la Información</p>
                                        </div>

                                        <div class="column is-4">
                                            <b-field label="Paridad"
                                                     label-position="on-border">
                                                <b-select expanded>
                                                    <option value="N">Ninguna</option>
                                                    <option value="E">Par</option>
                                                    <option value="O">Impar</option>
                                                </b-select>
                                            </b-field>
                                        </div>
                                        <div class="column is-8">
                                            <p>Tipo de Detección de Errores</p>
                                        </div>

                                        <div class="column is-4">
                                            <b-field label="Bits de datos"
                                                     label-position="on-border">
                                                <b-select expanded v-model="hardware.data_bits">
                                                    <option>Seleccionar</option>
                                                    <option value="7">7</option>
                                                    <option value="8">8</option>
                                                </b-select>
                                            </b-field>
                                        </div>
                                        <div class="column is-8">
                                            <p>Cantidad de Datos por Señalización</p>
                                        </div>

                                        <div class="column is-4">
                                            <b-field label="Bit de paro"
                                                     label-position="on-border">
                                                <b-select expanded v-model="hardware.stop_bit">
                                                    <option>Seleccionar</option>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                </b-select>
                                            </b-field>
                                        </div>
                                        <div class="column is-8">
                                            <p>Cantidad de Bits para marcar Fin de Datos</p>
                                        </div>
                                        <div class="column is-4">
                                            <b-field label="Protocolo"
                                                     label-position="on-border">
                                                <b-select expanded>
                                                    <option>Ninguno</option>
                                                    <option>Xon/Xoff</option>
                                                    <option>RTS/CTS</option>
                                                    <option>Ambos</option>
                                                </b-select>
                                            </b-field>
                                        </div>
                                        <div class="column is-8">
                                            <p>Forma de Establecer Comunicación con SyncCX7</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="column is-12 is-5-desktop is-4-widescreen has-background-white is-multiline"
                                     style="border-left: 2px #dbdbdb solid">
                                    <div class="is-divider"></div>
                                    <div>
                                        <b-checkbox>Agregar Observacion del Area</b-checkbox>
                                    </div>
                                    <div>
                                        <b-checkbox>Crear archivo de lo recibido</b-checkbox>
                                    </div>
                                    <div>
                                        <b-checkbox>Natural</b-checkbox>
                                    </div>
                                    <div>
                                        <b-checkbox>Leer tramas de un archivo</b-checkbox>
                                    </div>
                                    <div class="column is-12">
                                        <b-field label="Area de trabajo"
                                                 label-position="on-border">
                                            <b-select expanded>
                                                <option value="">Seleccionar</option>
                                            </b-select>
                                        </b-field>
                                    </div>


                                    <div class="columns is-multiline" style="margin-top: 8px">
                                        <div
                                                class="column is-12 is-6-desktop is-6-widescreen has-background-white is-multiline">
                                            <div class="is-divider" data-content="Fecha"></div>
                                            <div>
                                                <b-checkbox>El Día de Hoy</b-checkbox>
                                            </div>
                                            <div>
                                                <b-checkbox>Hace 15 días</b-checkbox>
                                            </div>
                                            <div>
                                                <b-checkbox>Desde el dia</b-checkbox>
                                                <b-input placeholder="04/02/2023"></b-input>
                                            </div>
                                        </div>

                                        <div class="column is-12 is-6-desktop is-6-widescreen has-background-white is-multiline"
                                             style="border-left: 2px #dbdbdb solid">
                                            <div class="is-divider" data-content="Ver"></div>

                                            <div>
                                                <b-checkbox>Todos</b-checkbox>
                                            </div>
                                            <div>
                                                <b-checkbox>Por Validarse</b-checkbox>
                                            </div>
                                            <div>
                                                <b-checkbox>Ya Validado</b-checkbox>
                                            </div>
                                        </div>
                                        <div class="column is-12">
                                            <b-button expanded
                                                      type="is-ghost">Ver Historial de Mensajes
                                            </b-button>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div class="buttons">
                                <b-button type="is-primary">Aceptar</b-button>
                                <b-button type="is-primary">Cancelar</b-button>
                            </div>


                        </b-tab-item>
                    </b-tabs>
                </section>
            </div>

        </div>

        <b-modal v-model="showValidationSign"
                 has-modal-card
                 trap-focus
                 width="80vw">
            <validation-sign/>
        </b-modal>
    </div>
</template>

<script>
import ValidationSign from "@/components/tools/ValidationSign";

export default {
    name: "Index",
    components: {ValidationSign},
    computed: {},
    data() {
        return {
            showValidationSign: false,
            hardwareList: [],
            selectedItem: {},
            isOpenMenu: false,
            hardware: {}
        }
    },
    mounted() {
        this.wrapper = document.getElementById('main-wrapper');
        this.wrapper.classList.add('is-expanded');
        document.addEventListener("keyup", this.handleKeyup);
        this.prepareData()
    },
    methods: {
        handleKeyup(event) {
            if (['Escape', 'F2', 'F1', 'F3'].indexOf(event.key) > -1) {
                this.$toast.open("Se presiono la tecla: " + event.key);
            }
        },
        prepareData() {
            this.$loader.show();
            this.$http.get('/hardware-list').then(({data}) => {
                console.log(data);
                this.hardwareList = data.list;
            }).catch(error => {
                this.$alfalab.showError(error);
            }).finally(() => {
                this.$loader.hide();
            })
        },
        handleMenuClick(item) {
            if(item.code){
                this.selectedItem = item;
                this.isOpenMenu = true;
                setTimeout(() => {
                    this.isOpenMenu = false;
                }, 100);
                this.getHardwareData();
            }
        },
        handleCloseMenu(e){
            console.log('c', e);
            this.isOpenMenu = false;
        },
        getHardwareData() {
            this.$loader.show();
            this.$http.get('/hardware/' + this.selectedItem.code).then(({data}) => {
              this.hardware = data.data;
            }).catch(error => {
                this.$alfalab.showError(error);
            }).finally(() => {
                this.$loader.hide();
            })
        }
    },
    beforeDestroy() {
        document.removeEventListener("keyup", this.handleKeyup);
    }
}
</script>

<style scoped>

</style>