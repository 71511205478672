<template>
  <div>
    <div class="section-title section-title_app">
      <h3>VALIDACIÓN DE RESULTADOS</h3>
    </div>
    <div class="columns is-multiline is-gapless">
      <div class="column is-12 has-background-white">
        <div style="display: flex; flex-wrap: wrap; gap: 8px; padding: 16px;">
          <div class="column is-4">
            <b-field label="Procedencia"
                     label-position="on-border">
              <b-select>
                <option>Seleccionar</option>
              </b-select>
            </b-field>
          </div>
          <div class="column is-4">
            <b-field label="Equipo"
                     label-position="on-border">
              <b-select>
                <option>Seleccionar</option>
              </b-select>
            </b-field>
          </div>
          <div class="column is-12 is-7-desktop is-12-widescreen is-9-fullhd has-background-white is-multiline">
            <div class="is-divider" data-content="VALIDACIÓN DE RESULTADOS PARA "></div>

            <div class="column is-12 has-background-white">
              <div style="display: flex; flex-wrap: wrap; gap: 16px; padding: 2px;">
                <b-tooltip label="Nueva">
                  <b-button icon-left="file" size="is-medium" type="is-primary"></b-button>
                </b-tooltip>
                <b-tooltip label="Guardar">
                  <b-button icon-left="save" size="is-medium" type="is-primary"></b-button>
                </b-tooltip>
                <b-tooltip label="Inicio">
                  <b-button icon-left="circle-arrow-left" size="is-medium" type="is-primary"></b-button>
                </b-tooltip>
                <b-tooltip label="Anterior">
                  <b-button icon-left="arrow-left" size="is-medium" type="is-primary"></b-button>
                </b-tooltip>
                <b-tooltip label="Siguiente">
                  <b-button icon-left="arrow-right" size="is-medium" type="is-primary"></b-button>
                </b-tooltip>
                <b-tooltip label="Fin">
                  <b-button icon-left="circle-arrow-right" size="is-medium" type="is-primary"></b-button>
                </b-tooltip>
                <div class="column is-2">
                  <b-field>
                    <b-checkbox>Validado</b-checkbox>
                  </b-field>
                </div>

                <div class="column is-2">
                  <b-field label="Fecha"
                           label-position="on-border">
                            2023/02/21
                  </b-field>
                </div>
                <div class="column is-2">
                  <b-field label="Hora"
                           label-position="on-border">

                  </b-field>
                </div>
                <div class="column is-2">
                  <b-field label="Usuario"
                           label-position="on-border">

                  </b-field>
                </div>
              </div>
            </div>

          </div>


        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EquipmentCommunication"
}
</script>

<style scoped>

</style>